<template>
  <v-select
      outlined solo flat
      return-object
      :value="value"
      @input="onInput"
      :items="items"
      item-text="text"
      item-value="value"
      :rules="validator.select"
      :label="label"
  ></v-select>
</template>

<script>
export default {
  name: "CustomSelect",
  props: [
      "value",
      "items",
      "label"
  ],
  data: () => ({
    validator: {},
  }),
  methods: {
    onInput(event) {
      this.$emit('input', event);
    }
  },
  mounted() {
    this.validator = {
      select: [
        (v => !!v?.value || this.$t('ERR_SELECT_REQUIRED'))
      ],
    }
  }
}
</script>

<i18n>
{
  "en": {
    "ERR_SELECT_REQUIRED": "Must choose one."
  }
}
</i18n>
